import React from 'react';
import './logo.css';

function Logo() {
  return (
    <div
      data-testid='logo'
      className='logo'>
      <h1>CoHomey</h1>
    </div>
  );
}

export default Logo;
