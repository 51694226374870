import React from 'react';
import './App.css';
import Logo from './logo/Logo';

function App() {
  return (
    <div className='app'>
      <header className='app-header'>
        <Logo />
        <h2 className='app-description'>
          CoHomey is a home to live and work
          <br />
          in the belly of Buenos Aires <br />
        </h2>
        <h3 className='app-text'>
          A calm and quiet environment
          <br />{' '}
          <span className='app-text app-text-second-line'>
            to work with the sound of birds
          </span>
        </h3>
      </header>
      <article className='app-form'>
        <h4 className='app-form-text'>
          Spots are limited and subject to availability, <br /> reserve your
          place{' '}
          <address>
            <a
              className='app-link'
              href='mailto:info@cohomey.co'>
              emailing us
            </a>
          </address>
        </h4>
      </article>
      <footer className='app-footer'>
        <p className='app-footer-copyright'>
          <small>&copy; Copyright 2024, CoHomey. All Rights Reserved</small>
        </p>
      </footer>
    </div>
  );
}

export default App;
